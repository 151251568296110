













































































































































































































import {Component, Vue} from 'vue-property-decorator';
import Navigation from '@/components/Navigation.vue';

@Component({
    components: {Navigation},
})
export default class PrivacyPolicy extends Vue {
}
